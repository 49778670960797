/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, Popover } from "antd";
import {
  AlignRightOutlined,
  AlignLeftOutlined,
  LoginOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Spinner from "../common/spinner";
import SiderBar from "./sidebar";

import { setUserData } from "../../utils/redux/actions";

import "./styles.scss";

const Dashboard = React.lazy(() => import("../dashboard"));
const Reports = React.lazy(() => import("../reports"));
const Profile = React.lazy(() => import("../admin/profile"));
const ChangePassword = React.lazy(() => import("../admin/password"));
const InterchangePointList = React.lazy(() =>
  import("../partners/interchange-point/interchange-point-list")
);
const AddInterchangePoint = React.lazy(() =>
  import("../partners/interchange-point/add-interchangepoint-list")
);
const ClearanceCompanyList = React.lazy(() =>
  import("../partners/clearance-company/clearance-company-list")
);
const AddClearanceCompany = React.lazy(() =>
  import("../partners/clearance-company/add-clearance-company")
);
const DeliveryCompanyList = React.lazy(() =>
  import("../partners/delivery-company/delivery-company-list")
);
const AddDeliveryCompany = React.lazy(() =>
  import("../partners/delivery-company/add-delivery company")
);
const ChannelsList = React.lazy(() => import("../channel/channel-list"));
const AddChannel = React.lazy(() => import("../channel/add-channel"));
const MasterPricingList = React.lazy(() =>
  import("../master-pricing/master-pricing-list")
);
const AddMasterPricing = React.lazy(() =>
  import("../master-pricing/add-master-pricing")
);
const UserList = React.lazy(() => import("../customers/user/user-list"));
const AddUser = React.lazy(() => import("../customers/user/add-user"));
const CompanyList = React.lazy(() =>
  import("../customers/company/company-list")
);
const AddCompany = React.lazy(() => import("../customers/company/add-company"));
const BookShipment = React.lazy(() => import("../shipment/book-shipment"));
const RecieveShipmentList = React.lazy(() =>
  import("../shipment/recieve-shipment/recieve-shipment-list")
);
const AddRecieveShipment = React.lazy(() =>
  import("../shipment/recieve-shipment/add-recieve-shipment")
);
const DeliverShipmentList = React.lazy(() =>
  import("../shipment/deliver-shipment/deliver-shipment-list")
);
const AddDeliverShipment = React.lazy(() =>
  import("../shipment/deliver-shipment/add-deliver-shipment")
);
const ShipmentList = React.lazy(() => import("../shipment/shipment-list"));
const AwbInvoice = React.lazy(() => import("../shipment/awb-invoice"));
const AwbInvoiceSearch = React.lazy(() =>
  import("../shipment/awb-invoice/searchawb")
);
const TrackShipment = React.lazy(() => import("../shipment/track-shipment"));
const SearchAwb = React.lazy(() =>
  import("../shipment/track-shipment/search-track")
);
const ProcessShipment = React.lazy(() =>
  import("../shipment/process-shipment")
);
const AddShipmentProcess = React.lazy(() =>
  import("../shipment/process-shipment/add-shipment-process")
);
const AppSettings = React.lazy(() => import("../settings/app-settings"));
const BannerList = React.lazy(() =>
  import("../settings/banner-settings/banner-list")
);
const AddBanner = React.lazy(() =>
  import("../settings/banner-settings/add-banner")
);
const StaffList = React.lazy(() =>
  import("../settings/staff-settings/staff-list")
);
const AddStaff = React.lazy(() =>
  import("../settings/staff-settings/add-staff")
);
const StaffDetails = React.lazy(() =>
  import("../settings/staff-settings/staff-details")
);
const Payments = React.lazy(() => import("../payments"));
const AddPayment = React.lazy(() => import("../payments/add-payment"));
const PurchaseDetails = React.lazy(() => import("../payments/payment-details"));
const WareHouseList = React.lazy(() => import("../Administration/warehouse"));
const AddWareHouse = React.lazy(() =>
  import("../Administration/warehouse/add-warehouse")
);
const AdminAndStaffList = React.lazy(() =>
  import("../Administration/admin-and-staff")
);
const AddAdminAndStaff = React.lazy(() =>
  import("../Administration/admin-and-staff/add-admin-and-staff")
);
const GlobalSettings = React.lazy(() =>
  import("../Administration/global-settings")
);
const TermsandPolicies = React.lazy(() => import("../terms-and-policies"));
const CloseTicketsList = React.lazy(() =>
  import("../support/close-ticket/close-ticket-list")
);
const OpenTicketsList = React.lazy(() =>
  import("../support/open-ticket/open-tickets-list")
);
const OpenTicket = React.lazy(() => import("../support/open-ticket"));
const CloseTicket = React.lazy(() => import("../support/close-ticket"));
const FAQs = React.lazy(() => import("../Faqs"));
const AddFaqs = React.lazy(() => import("../Faqs/add-faq"));

const Messages = React.lazy(() => import("../messages"));

const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
    setOpen(false);
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="profile-opt" onClick={handleProfileClick}>
          <Avatar size={20} /*src="https://joeschmoe.io/api/v1/random"*/>
            P
          </Avatar>
          Profile
        </div>
        <div className="password-opt" onClick={handleChangeLogout}>
          <LoginOutlined />
          Change Password
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  const openMenu = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate(`/admin/profile`);
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("dropship-admin-panel-user-data");
    dispatch(setUserData({}));
    navigate("/login");
  };

  return (
    <Layout>
      <SiderBar toggle={toggle} collapsed={collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background bugify-navbar">
          <div className="collapse-btn" onClick={toggle}>
            {collapsed ? (
              <AlignRightOutlined className="trigger" />
            ) : (
              <AlignLeftOutlined className="trigger" />
            )}
          </div>
          <Space align="center" size={20}>
            <Popover
              placement="rightBottom"
              content={userPopover()}
              trigger="click"
              className="user-popover"
              open={open}
            >
              <Avatar
                onClick={openMenu}
                shape="square"
                style={{
                  backgroundColor: "#87d068",
                }}
                icon={<UserOutlined />}
              />
            </Popover>
          </Space>
        </Header>
        <Content className="site-layout-background content-main-section">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/reports" element={<Reports />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/admin/profile" element={<Profile />} />
              <Route
                path="/admin/change-password"
                element={<ChangePassword />}
              />
              <Route
                path="/partners/interchange-point"
                element={<InterchangePointList />}
              />
              <Route
                path="/partners/interchange-point/add"
                element={<AddInterchangePoint />}
              />
              <Route
                path="/partners/interchange-point/edit/:id"
                element={<AddInterchangePoint />}
              />
              <Route
                path="/partners/clearance-companies"
                element={<ClearanceCompanyList />}
              />
              <Route
                path="/partners/clearance-company/add"
                element={<AddClearanceCompany />}
              />
              <Route
                path="/partners/clearance-company/edit/:id"
                element={<AddClearanceCompany />}
              />
              <Route
                path="/partners/delivery-companies"
                element={<DeliveryCompanyList />}
              />
              <Route
                path="/partners/delivery-company/add"
                element={<AddDeliveryCompany />}
              />
              <Route
                path="/partners/delivery-company/edit/:id"
                element={<AddDeliveryCompany />}
              />
              <Route path="/channels-list" element={<ChannelsList />} />
              <Route path="/channel/add" element={<AddChannel />} />
              <Route path="/channel/edit/:id" element={<AddChannel />} />
              <Route path="/master-pricing" element={<MasterPricingList />} />
              <Route
                path="/master-pricing/add"
                element={<AddMasterPricing />}
              />
              <Route
                path="//master-pricing/edit/:id"
                element={<AddMasterPricing />}
              />
              <Route path="/customer/user" element={<UserList />} />
              <Route path="/customer/user/add" element={<AddUser />} />
              <Route path="/customer/user/edit/:id" element={<AddUser />} />
              <Route path="/customers/company" element={<CompanyList />} />
              <Route path="/customers/company/add" element={<AddCompany />} />
              <Route
                path="/customers/company/edit/:id"
                element={<AddCompany />}
              />
              <Route
                path="/shipment/book-shipment"
                element={<BookShipment />}
              />

              <Route
                path="/shipment/book-shipment/edit/:id"
                element={<BookShipment />}
              />
              <Route
                path="/shipment/recieve-shipment"
                element={<RecieveShipmentList />}
              />
              <Route
                path="/shipment/recieve-shipment/search"
                element={<AddRecieveShipment />}
              />
              <Route
                path="/shipment/recieve-shipment/edit/:id"
                element={<AddRecieveShipment />}
              />
              <Route
                path="/shipment/assign-shipment"
                element={<DeliverShipmentList />}
              />
              <Route
                path="/shipment/assign-shipment/search"
                element={<AddDeliverShipment />}
              />
              <Route
                path="/shipment/assign-shipment/edit/:id"
                element={<AddDeliverShipment />}
              />
              <Route
                path="/shipment/shipment-list"
                element={<ShipmentList />}
              />
              <Route
                path="/shipment/AWB-bill-invoice/:awb"
                element={<AwbInvoice />}
              />
              <Route
                path="/shipment/AWB-bill-invoice/search"
                element={<AwbInvoiceSearch />}
              />
              <Route path="/shipment/track/:awb" element={<TrackShipment />} />
              <Route path="/shipment/track/search" element={<SearchAwb />} />
              <Route
                path="/shipment/process-shipment"
                element={<ProcessShipment />}
              />
              <Route
                path="/dashboard/shipment/process-shipment/search"
                element={<AddShipmentProcess />}
              />
              <Route path="/dashboard/payment" element={<Payments />} />
              <Route path="/dashboard/payment/add" element={<AddPayment />} />
              <Route
                path="/dashboard/payment/details/:id"
                element={<PurchaseDetails />}
              />
              <Route
                path="/dashboard/warehouse-list"
                element={<WareHouseList />}
              />
              <Route
                path="/dashboard/warehouse-list/add"
                element={<AddWareHouse />}
              />
              <Route
                path="/dashboard/warehouse-list/edit/:id"
                element={<AddWareHouse />}
              />
              <Route
                path="/dashboard/admin-and-staff-list"
                element={<AdminAndStaffList />}
              />
              <Route
                path="/dashboard/admin-and-staff/add"
                element={<AddAdminAndStaff />}
              />
              <Route
                path="/dashboard/admin-and-staff/edit/:id"
                element={<AddAdminAndStaff />}
              />
              <Route path="/global-settings" element={<GlobalSettings />} />
              <Route
                path="/terms-and-policies"
                element={<TermsandPolicies />}
              />
              <Route path="/open-tickets" element={<OpenTicketsList />} />
              <Route path="/edit-open-ticket/:id" element={<OpenTicket />} />
              <Route path="/close-tickets" element={<CloseTicketsList />} />
              <Route path="/open-ticket" element={<OpenTicket />} />
              <Route path="/close-ticket" element={<CloseTicket />} />
              <Route path="/edit-close-ticket/:id" element={<CloseTicket />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/faqs/add" element={<AddFaqs />} />
              <Route path="/faq/edit-faq/:id" element={<AddFaqs />} />
              <Route path="/settings/app-settings" element={<AppSettings />} />
              <Route path="/settings/list-banner" element={<BannerList />} />
              <Route path="/settings/add-banner" element={<AddBanner />} />
              <Route path="/settings/edit-banner/:id" element={<AddBanner />} />
              <Route path="/settings/list-staff" element={<StaffList />} />
              <Route path="/settings/add-staff" element={<AddStaff />} />
              <Route path="/settings/edit-staff/:id" element={<AddStaff />} />
              <Route
                path="/settings/staff-details/:id"
                element={<StaffDetails />}
              />

              {/* <Route path="/faq/edit-faq/:id" element={<AddFAQ />} /> */}

              <Route path="/messages" element={<Messages />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
