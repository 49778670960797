import Auth from "./Auth";
import Faq from "./Faq";
import Ticket from "./Ticket";
import Warehouse from "./Warehouse";
import FixedPage from "./FixedPage";
import Staff from "./Staff";
import Categories from "./Categories";
import Global from "./Global";
import Dashboard from "./Dashboard";
import Partners from "./Partners";
import Channel from "./Channels";
import Pricing from "./Pricing";
import Customer from "./Customers";
import Shipment from "./Shipment";
import Payment from "./Payment";

const API = {
  Auth,
  Faq,
  Ticket,
  Warehouse,
  FixedPage,
  Categories,
  Global,
  Staff,
  Dashboard,
  Partners,
  Channel,
  Pricing,
  Customer,
  Shipment,
  Payment,
};

export default API;
