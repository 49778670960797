/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, Input, Button, message, Row, Col, Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import Spinner from "../../common/spinner";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";

import authImg from "../../../images/Login Graphics.svg";
import logo from "../../../images/Dropship-logo.png";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const ResetPassword = () => {
  const [form] = Form.useForm();

  const auth = new API.Auth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState();

  const handleOtpChange = (o) => {
    setVerificationCode(o);
  };

  const requestForgetPassword = (payload) => {
    getDataManager(auth?.forgetPassword, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        message.success({
          content: "A code has been sent to your mobile",
          duration: 3,
        });
        setShowOtp(true);
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content: x?.message || error || "Error ocurred while sending otp",
          duration: 3,
        });
      }
    });
  };

  const createNewPassword = (values) => {
    if (verificationCode.length === 6) {
      const payload = {
        username: username,
        otp_code: verificationCode,
        password: values.password,
      };
      getDataManager(auth?.forgetPasswordConfirm, setLoading, payload).then(
        (x) => {
          if (x?.status === "success") {
            message.success({
              content: "Password has been updated successfully",
              duration: 3,
            });
            navigate("/login");
          } else {
            const error = getErrorMessage(x?.errors);
            message.error({
              content: x?.message || error || "Error ocurred while sending otp",
              duration: 3,
            });
          }
        }
      );
    } else {
      message.error({ content: "Please enter the otp!", duration: 3 });
    }
  };

  return (
    <Row>
      <Col span={12} className="login-right-section">
        <img src={authImg} alt="" />
        <div className="main-tagline">
          Welcome! A few click and access to dashboard
        </div>
        <div className="sub-tagline">Manage all your system in one place</div>
      </Col>
      <Col span={12} className="login-left-section">
        {!showOtp ? (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <div className="logo-container">
              <img src={logo} alt="" className="logo" />
            </div>
            <Title level={4} className="reset-password-title">
              Password Reset
            </Title>
            <Paragraph className="description">
              Forgot your password? Enter your email address, and we'll send you
              an email allowing you to reset it.
            </Paragraph>
            <Form
              form={form}
              onFinish={requestForgetPassword}
              scrollToFirstError
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter username",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" size="large" type="primary">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <Form form={form} onFinish={createNewPassword} scrollToFirstError>
              <Form.Item>
                <OtpInput
                  value={verificationCode}
                  onChange={handleOtpChange}
                  numInputs={6}
                  containerStyle="verification-code-container"
                  inputStyle="one-digit-input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
              >
                <Input placeholder="New password" size="large" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" size="large" type="primary">
                  Password Reset
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default ResetPassword;
