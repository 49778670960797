import React from "react";
import { Layout, Menu } from "antd";

import DashboardImg from "../../../images/Dashboard.svg";
import PartnerImg from "../../../images/Partners.svg";
import ChannelsImg from "../../../images/Channels.svg";
import MasterPricingImg from "../../../images/Master Pricing.svg";
import CustomerImg from "../../../images/Customers.svg";
import ShipmentImg from "../../../images/Shipment.svg";
import PaymentImg from "../../../images/Payment.svg";
import SupportImg from "../../../images/support-svgrepo-com.svg";
import AdministrationImg from "../../../images/Administration.svg";
import ReportsImg from "../../../images/Reports.svg";
import FAQsImg from "../../../images/faqs.svg";
import PrivacyPolicyImg from "../../../images/privacy-policy.svg";
import { useNavigate } from "react-router-dom";

import DropshipLogo from "../../../images/Dropship-logo.png";
import Favicon from "../../../images/favicon-emenem.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderBar = ({ toggle, collapsed }) => {
  const navigate = useNavigate();

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      {collapsed ? (
        <div className="dropship-logo-collapsed">
          <img src={Favicon} alt="" />
        </div>
      ) : (
        <div className="dropship-logo">
          <img src={DropshipLogo} alt="" />
        </div>
      )}
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item
          icon={<img src={DashboardImg} alt="" />}
          onClick={() => navigate("/dashboard")}
          key="1"
        >
          Dashboard
        </Menu.Item>
        <SubMenu
          key="2"
          icon={<img src={PartnerImg} alt="" />}
          title="Partners"
        >
          <Menu.Item
            key="2a"
            onClick={() => navigate("/partners/interchange-point")}
          >
            Interchange Point
          </Menu.Item>
          <Menu.Item
            key="2b"
            onClick={() => navigate("/partners/clearance-companies")}
          >
            Clearance Company
          </Menu.Item>
          <Menu.Item
            key="2c"
            onClick={() => navigate("/partners/delivery-companies")}
          >
            Delivery Company
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="3"
          icon={<img src={ChannelsImg} alt="" />}
          onClick={() => navigate("/channels-list")}
        >
          Channels
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<img src={MasterPricingImg} alt="" />}
          onClick={() => navigate("/master-pricing")}
        >
          Master Pricing
        </Menu.Item>
        <SubMenu
          key="5"
          icon={<img src={CustomerImg} alt="" />}
          title="Customers"
        >
          <Menu.Item key="5a" onClick={() => navigate("/customer/user")}>
            User
          </Menu.Item>
          <Menu.Item key="5b" onClick={() => navigate("/customers/company")}>
            Company
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="6"
          icon={<img src={ShipmentImg} alt="" />}
          title="Shipment"
        >
          <Menu.Item
            key="6a"
            onClick={() => navigate("/shipment/book-shipment")}
          >
            Book a Shipment
          </Menu.Item>
          <Menu.Item
            key="6b"
            onClick={() => navigate("shipment/recieve-shipment/search")}
          >
            Receive a Shipment
          </Menu.Item>
          <Menu.Item
            key="6c"
            onClick={() =>
              navigate("dashboard/shipment/process-shipment/search")
            }
          >
            Process a Shipment
          </Menu.Item>
          <Menu.Item
            key="6d"
            onClick={() => navigate("/shipment/assign-shipment/search")}
          >
            Deliver a Shipment
          </Menu.Item>
          <Menu.Item
            key="6e"
            onClick={() => navigate("/shipment/shipment-list")}
          >
            Shipment List
          </Menu.Item>
          <Menu.Item
            key="6f"
            onClick={() => navigate("/shipment/AWB-bill-invoice/search")}
          >
            AWB Invoice
          </Menu.Item>
          <Menu.Item
            key="6g"
            onClick={() => navigate("/shipment/track/search")}
          >
            Shipment Tracking
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="7"
          icon={<img src={PaymentImg} alt="" />}
          onClick={() => navigate("/dashboard/payment")}
        >
          Payment
        </Menu.Item>
        <SubMenu key="8" icon={<img src={SupportImg} alt="" />} title="Support">
          <Menu.Item key="8a" onClick={() => navigate("/open-tickets")}>
            Open Ticket
          </Menu.Item>
          <Menu.Item key="8b" onClick={() => navigate("/close-tickets")}>
            Close Ticket
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="9"
          icon={<img src={AdministrationImg} alt="" />}
          title="Administration"
        >
          <Menu.Item
            key="9a"
            onClick={() => navigate("/dashboard/warehouse-list")}
          >
            Warehouse
          </Menu.Item>
          <Menu.Item
            key="9b"
            onClick={() => navigate("/dashboard/admin-and-staff-list")}
          >
            Admin & Staff
          </Menu.Item>
          <Menu.Item key="9c" onClick={() => navigate("/global-settings")}>
            Global Settings
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="10"
          icon={<img src={ReportsImg} alt="" />}
          onClick={() => navigate("/reports")}
        >
          Reports
        </Menu.Item>

        <Menu.Item
          key="11"
          icon={<img src={FAQsImg} alt="" />}
          onClick={() => navigate("/faqs")}
        >
          FAQs
        </Menu.Item>
        <Menu.Item
          key="12"
          icon={<img src={PrivacyPolicyImg} alt="" />}
          onClick={() => navigate("/terms-and-policies")}
        >
          Terms & Policies
        </Menu.Item>

        <div className="copyright-text">Copyright © 2022 Dropship</div>
      </Menu>
    </Sider>
  );
};

export default SiderBar;
